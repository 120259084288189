import React from "react";
import Content from "../../../components/containers/Content";
import {
  CHANGE_ALL_MY_OFFERS_DETAILS,
  MANAGEMENT_INVENTORIES_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../../components/Pager";
import BlankPage from "../../../components/BlankPage";
import Button from "../../../components/common/Button";
import { Icons } from "../../../constants/icons";
import Filter from "../../../components/Filter";
import { getSorting } from "../../../functions/FilterFunctions";
import MerModal from "../../../components/common/MerModal";
import InventoryOrderSubmitModal from "./components/InventoriesSubmitOrderModal";
import Loader from "../../../components/Loader";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    inventories: state.order.inventories,
    InventorySummary: state.order.InventorySummary,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  InventoriesLoad: (payload) => {
    dispatch({ type: MANAGEMENT_INVENTORIES_PAGE_LOADED, payload });
  },
  InventorySummaryLoad: (value) => {
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "InventorySummary",
      value,
    });
  },

  SelectInventory: (value) => {
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "SelectedInventory",
      value,
    });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

class Inventories extends React.Component {
  constructor() {
    super();
    this.state = {
      showTableRow: false,
      selectedRowId: undefined,
      selectedItem: undefined,
      pageindex: 0,
      showModal: false,
    };
  }

  componentDidMount() {
    document.title = "Inventories";
    this.getInventories();
    this.getInventorySummary();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  getInventorySummary() {
    Promise.all([services.orders.InventorySummary()])
      .then((res) => {
        this.props.InventorySummaryLoad(res[0]);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  getInventories(params) {
    const {
      pageIndex = this.state.pageindex,
      pagesize = this.props.pageSize,
      keywords = "",
      store = "",
    } = params || {};
    this.setState({ pageindex: pageIndex });
    return this.props.InventoriesLoad(
      Promise.all([
        services.orders.inventories(pageIndex, pagesize, keywords, store),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  onFilter(params) {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;
    sortBy = getSorting(sortBy, this.state.sortBy);
    this.setState({ sortBy: sortBy });

    this.getInventories({
      keywords: ev?.Text,
      SortBy: sortBy,
      pageIndex: pageindex,
      store: ev?.Store,
    });
  }

  onChangePageIndex(pageindex) {
    this.onFilter({ pageindex: pageindex });
    this.setState({ showTableRow: false });
  }

  modalClose = () => {
    this.setState({ showModal: false });
  };

  render() {
    const inventories =
      this.props.inventories !== undefined
        ? this.props.inventories[0]
        : this.props.inventories;

    return (
      <Content pageTitle="Inventories">
        {inventories === undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              onFilter={(ev, sortBy) => this.onFilter(ev, sortBy)}
              isStoreAvailable
            ></Filter>
            {inventories?.length === 0 ? (
              <BlankPage description="There is no Inventory." />
            ) : (
              <div>
                <div className="table-total-count mt-3">
                  <div className="d-flex flex-column ">
                    <div className="d-flex justify-content-end">
                      <p className="mx-5">
                        <strong>Available Cost: </strong>
                        {this.props.InventorySummary?.AvailableCost.toFixed(2)}€
                      </p>
                      <p>
                        <strong>Remaining Cost: </strong>
                        {this.props.InventorySummary?.RemainingCost.toFixed(2)}€
                      </p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p className="mx-5">
                        <strong>Available Count: </strong>
                        {this.props.InventorySummary?.AvailableCount}
                      </p>
                      <p>
                        <strong>Remaining Cost: </strong>
                        {this.props.InventorySummary?.RemainingCount}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">
                          Product Sku
                        </th>
                        <th className="bg-transparent border-0 ">
                          Product Name
                        </th>
                        <th className="bg-transparent border-0 ">Store</th>
                        <th className="bg-transparent border-0 ">
                          Total Avg. Cost
                        </th>
                        <th className="bg-transparent border-0 ">
                          Available Count
                        </th>
                        <th className="bg-transparent border-0 ">
                          Remaining Count
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {inventories?.Items?.map((item, key) => {
                        return (
                          <tr
                            key={key}
                            className="rounded-1 mb-1 align-middle border-bottom"
                            onClick={() =>
                              this.setState({
                                showTableRow:
                                  key === this.state.selectedRowId
                                    ? !this.state.showTableRow
                                    : true,
                                selectedRowId: key,
                                selectedItem: item,
                              })
                            }
                          >
                            <td className="fmxw-350 bg-white border-0 text-gray-600 p-4">
                              {item.ProductSku}
                            </td>
                            <td className="fmxw-350 bg-white border-0 text-gray-600 p-4">
                              {item.ProductName}
                            </td>

                            <td className="fmxw-350 bg-white border-0 text-gray-600 p-4">
                              {item.ProductStore}
                            </td>
                            <td className="fmxw-350 bg-white border-0 text-gray-600 p-4">
                              <p>
                                €
                                {item.InventoryItems?.map(
                                  (inv) => inv.AvgCost * inv.AvailableCount
                                ).reduce((a, b) => a + b, 0) /
                                item.InventoryItems?.map(
                                  (inv) => inv.AvailableCount
                                ).reduce((a, b) => a + b, 0)
                                  ? (
                                      item.InventoryItems?.map(
                                        (inv) =>
                                          inv.AvgCost * inv.AvailableCount
                                      ).reduce((a, b) => a + b, 0) /
                                      item.InventoryItems?.map(
                                        (inv) => inv.AvailableCount
                                      ).reduce((a, b) => a + b, 0)
                                    ).toFixed(2)
                                  : "0.00"}
                              </p>

                              {item.InventoryItems?.length > 1 && (
                                <>
                                  (
                                  {item.InventoryItems?.map((inv) => (
                                    <>
                                      {inv.Type === "InventoryStandard"
                                        ? "S:"
                                        : ", I:"}
                                      {inv.AvgCost}
                                    </>
                                  ))}
                                  )
                                </>
                              )}
                            </td>
                            <td className="fmxw-350 bg-white border-0 text-gray-600 p-4">
                              {item.InventoryItems?.map((inv) => {
                                return (
                                  <p>
                                    {inv.Type === "InventoryStandard"
                                      ? "Standart"
                                      : inv.Type}{" "}
                                    : {inv.AvailableCount}
                                  </p>
                                );
                              })}
                            </td>
                            <td className="fmxw-350 bg-white border-0 text-gray-600 p-4">
                              {item.InventoryItems?.map((inv) => {
                                return (
                                  <p>
                                    {inv.Type === "InventoryStandard"
                                      ? "Standart"
                                      : inv.Type}{" "}
                                    : {inv.RemainingCount}
                                  </p>
                                );
                              })}
                            </td>
                            <td className="mb-0">
                              <Button
                                className="confirm-button px-3"
                                bindEvent={() => {
                                  this.props.SelectInventory(item);
                                  this.setState({
                                    showModal: !this.state.showModal,
                                  });
                                }}
                                label="Create Order"
                                buttonIcon={Icons.thickIcon}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="table-total-count mt-3">
                  Total Count: {inventories?.TotalCount}
                </div>
                <Pager
                  pageIndex={inventories?.PageIndex}
                  totalPages={inventories?.TotalPages}
                  totalCount={inventories?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
                <MerModal
                  showModal={this.state.showModal}
                  ariaLabelledby="contained-modal-title-vcenter"
                  onHide={() => this.modalClose()}
                >
                  <InventoryOrderSubmitModal
                    type={this.state.type}
                    selectedItem={this.state.selectedItem}
                    handleClose={() => this.modalClose()}
                  />
                </MerModal>
              </div>
            )}
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventories);
