import React, { useState, useEffect } from "react";
import { Icons } from "../../../../../constants/icons";
import Button from "../../../../../components/common/Button";
import services from "../../../../../api/index";
import NumberInput from "../../../../../components/common/NumberInput";
import NumberFormatter from "../../../../../components/common/NumberFormatter";
import { toast } from "react-toastify";

const ManagementOrderEditModal = (props) => {
  const [totalExtAmount, setTotalAmount] = useState(() => {
    return props.order.order[0]?.TotalExcVatAmount;
  });

  const [offerPrice, setOfferPrice] = useState(() => {
    console.log(props.order.order[0]?.Offer.OfferPrice);
    return props.order.order[0]?.Offer.OfferPrice;
  });

  const [oldOfferPrice, setOldOfferPrice] = useState(() => {
    return props.order.order[0]?.Offer.OfferPrice;
  });
  const [isUpdate, setOrderUpdate] = useState(false);

  const onChange = (value) => {
    let newPrice = parseFloat(value);

    console.log(value);
    setTotalAmount(
      parseInt(props.order.order[0]?.Offer.OfferQuantity) * parseFloat(newPrice)
    );
    setOfferPrice(newPrice);
  };

  const onSubmit = () => {
    if (parseFloat(offerPrice) == parseFloat(oldOfferPrice)) {
      toast.error(<div>You haven't made any changes yet !</div>);
      return;
    }

    Promise.all([
      services.orders
        .updateOrderOfferPrice(props.order.order[0]?.Id, offerPrice)
        .then((res) => {
          toast.success(<div>Updated Offer Price!</div>);
          props.closeModal();

          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        }),
    ]);
  };
  return (
    <div className="container create-package-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div class="form-group">
          <div>
            <NumberInput
              label="Offer Price"
              centerAlign
              value={offerPrice}
              onChange={(ev) => {
                onChange(ev);
              }}
              visible
              notFlex={1}
            ></NumberInput>
            <small id="help" class="form-text text-muted">
              Old Offer Price:{" "}
              <NumberFormatter offerPrice={oldOfferPrice} supVisible />
            </small>
          </div>

          <label for="exampleInputEmail1">
            Total Amount :
            <NumberFormatter offerPrice={totalExtAmount} supVisible />
          </label>
          <small id="ordertax" class="form-text text-muted"></small>
        </div>

        <div className="px-4 p-2 rounded-1 m-auto"></div>
        <div>
          <Button
            disabled={props.btnDisable}
            className="order-confirm-yes-button"
            bindEvent={() => onSubmit()}
            label="Update"
            buttonIcon={Icons.thickIcon}
          />

          <Button
            className="order-confirm-no-button"
            bindEvent={() => props.closeModal()}
            label="Go Back"
            buttonIcon={Icons.leftArrowIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default ManagementOrderEditModal;
