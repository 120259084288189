import React from "react";
import moment from "moment";
import {
  OrderStatus,
  partner,
  salesman,
  superadmin,
} from "../../../../../constants/defaults";
import Button from "../../../../../components/common/Button";
import MerModal from "../../../../../components/common/MerModal";
import { connect } from "react-redux";
import {
  SELECT_ALL_OFFERS,
  CHANGE_ALL_MY_OFFERS_DETAILS,
  OFFER_DETAILS,
  REFRESH_ITEM,
} from "../../../../../constants/actionTypes";
import { getLatestOrder } from "../../../../../functions/orderValues";
import { convertLocalToUTCDate } from "../../../../../functions/dateConverter";
import ManagementOrderModalRightSide from "../modal/ManagementOrderModalRightSide";
import ManagementOrderModalLeftSide from "../modal/ManagementOrderModalLeftSide";
import ManagementCreatePackageModal from "../modal/ManagementCreatePackageModal";
import ManagementSendPackageModal from "../modal/ManagementSendPackageModal";
import ManagementOrderConfirm from "../modal/ManagementOrderConfirm";
import { Endpoints } from "../../../../../constants/endpoints";
import { Link } from "react-router-dom";
import { Icons } from "../../../../../constants/icons";
import { toast } from "react-toastify";
import ManagementOrderSendToInventoryConfirm from "../modal/ManagementOrderSendToInventoryConfirm";
import settings from "../../../../../settings.json";
import services from "../../../../../api/index";
import ManagementOrderCancelModal from "../modal/ManagementOrderCancelModal";
import { VLCHash } from "../../../../../functions/keyGenerate";

const SYSTEM_PARTNER_ID = settings["SYSTEM_PARTNER_ID"];

const mapStateToProps = (state) => {
  return { order: state.order, currentUser: state.auth.currentUser };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeCustomPrice: (value) =>
    dispatch({ type: OFFER_DETAILS, key: "customPrice", value }),
  onSelectOrders: (offer) => dispatch({ type: SELECT_ALL_OFFERS, offer }),
  onRefreshItem: (order) => dispatch({ type: REFRESH_ITEM, payload: order }),

  onChangeDemandQuantity: (value) =>
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "parentDemandQuantity",
      value,
    }),
  onChangeDemandPrice: (value) =>
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "parentDemandPrice",
      value,
    }),
});

class ManagementOrderCollapseBody extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showSendPackageModal: false,
      showCreatePackageModal: false,
      showConfirm: false,
      sendInventoryConfirm: false,
      cancelOrderModalShow: false,
    };
  }
  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleCreatePackageClose = () => {
    this.setState({ showCreatePackageModal: false });
  };

  handleSendPackageClose = () => {
    this.setState({ showSendPackageModal: false });
  };

  confirmModalClose = () => {
    this.setState({ showConfirm: false });
  };

  confirmModalOpen = () => {
    this.setState({ showConfirm: true });
  };

  sendInventoryConfirmModalClose = () => {
    this.setState({ sendInventoryConfirm: false });
  };

  sendInventoryConfirmModalOpen = () => {
    this.setState({ sendInventoryConfirm: true });
  };

  selectOrder = (offer) => {
    this.props.onChangeCustomPrice(false);
    const latestOrder = getLatestOrder(offer.History, true);
    this.props.onChangeDemandQuantity(latestOrder?.OfferQuantity);
    this.props.onChangeDemandPrice(latestOrder?.OfferPrice);
    this.props.onSelectOrders(offer);
  };

  downloadTxtFile = (content) => {
    const element = document.createElement("a");
    const file = new Blob([content], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "keys.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  refreshItem = (id) => {
    this.props.onRefreshItem(
      Promise.all([services.orders.getManagementOrder(id)])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  render() {
    return (
      <div className="card-body">
        <div className="col-11">
          <div className="order-buttons">
            <Button
              className="confirm-button px-4"
              bindEvent={() => this.setState({ showConfirm: true })}
              visible={this.props.item?.Status === OrderStatus.Submitted}
              label="Approve Order"
              buttonIcon={Icons.thickIcon}
            />

            {(this.props.item?.Status === OrderStatus.Level1Approved ||
              this.props.item?.Status === OrderStatus.SalesmanApproved) &&
            (this.props.currentUser?.scope[0] === superadmin ||
              this.props.currentUser?.scope[0] === salesman) ? (
              <Button
                className="confirm-button px-4"
                bindEvent={() => this.setState({ showConfirm: true })}
                visible
                label="Approve Order"
                buttonIcon={Icons.thickIcon}
              />
            ) : null}
            <Button
              className="reject-button px-4"
              bindEvent={() => this.props.refuse(this.props.item?.Id)}
              visible={
                this.props.item?.Status === OrderStatus.SalesmanApproved &&
                this.props.currentUser?.scope[0] === salesman
              }
              label="Refuse"
              buttonIcon={Icons.crossIcon}
            />
            <Button
              disabled={this.props.btnDisable}
              className="reprocess-button px-4"
              bindEvent={() => this.props.pause(this.props.item?.Id)}
              visible={this.props.item?.Status === OrderStatus.Processing}
              confirmable
              label=" Pause Order"
              buttonIcon={Icons.crossIcon}
            />
            <Button
              disabled={this.props.btnDisable}
              className="reprocess-button px-4"
              bindEvent={() => this.props.reprocess(this.props.item?.Id)}
              visible={this.props.item?.Status === OrderStatus.Paused}
              confirmable
              label=" Resume Order"
              buttonIcon={Icons.reprocessIcon}
            />
            <Button
              className="order-detail-button px-4"
              bindEvent={() => [
                this.setState({ showModal: true }),
                this.selectOrder(this.props.item),
              ]}
              visible={
                this.props.item?.Status === OrderStatus.Submitted ||
                this.props.item?.Status === OrderStatus.Revoked ||
                (this.props.item?.Status === OrderStatus.AdminRefused &&
                  this.props.currentUser?.scope[0] === salesman) ||
                (this.props.item?.Status === OrderStatus.SalesmanApproved &&
                  this.props.currentUser?.scope[0] === superadmin)
              }
              label=" See Details"
              buttonIcon={Icons.seeDetailIcon}
            />
            <Button
              className="confirm-button px-4"
              bindEvent={() => {
                let content = "";
                for (
                  let i = 0;
                  i <
                  this.props.item.Offer.OfferQuantity -
                    this.props.item.CompletedQuantity;
                  i++
                ) {
                  content += VLCHash(this.props.item.OrderNumber + i) + `\n`;
                }
                this.downloadTxtFile(content);
              }}
              visible={
                this.props.item.Offer.OfferQuantity -
                  this.props.item.CompletedQuantity >
                0
              }
              label=" Download VL Keys"
              buttonIcon={Icons.keyIcon}
            />
            <Button
              disabled={this.props.btnDisable}
              className="withdraw-button px-4"
              bindEvent={() => this.props.revoke(this.props.item?.Id)}
              showConfirm
              visible={this.props.item?.Status === OrderStatus.Rebidding}
              label="Revoke"
              confirmable
              buttonIcon={Icons.rightArrow45Icon}
            />
            <Button
              className="create-package-button px-4"
              bindEvent={() => this.setState({ showCreatePackageModal: true })}
              visible={
                this.props.item?.CompletedQuantity -
                  this.props.item?.DeliveredQuantity !==
                  0 &&
                (this.props.item?.Status === OrderStatus.Processing ||
                  this.props.item?.Status === OrderStatus.Completed ||
                  this.props.item?.Status === OrderStatus.PromotionExpired ||
                  this.props.item?.Status === OrderStatus.Closed) &&
                this.props.item?.Partner?.PartnerId !== SYSTEM_PARTNER_ID
              }
              label=" Create Package"
              buttonIcon={Icons.createPackageIcon}
            />
            <Button
              className="send-package-button px-4"
              bindEvent={() => this.setState({ showSendPackageModal: true })}
              visible={
                (this.props.item?.Status === OrderStatus.Processing ||
                  this.props.item?.Status === OrderStatus.Completed ||
                  this.props.item?.Status === OrderStatus.PromotionExpired ||
                  this.props.item?.Status === OrderStatus.Closed) &&
                this.props.item?.Packages.length > 0
              }
              label=" Send Package"
              buttonIcon={Icons.sendPackageIcon}
            />
            <Button
              className="confirm-button px-4"
              bindEvent={() => this.props.refund(this.props.item?.Id)}
              visible={
                (this.props.item?.Status === OrderStatus.Completed ||
                  this.props.item?.Status === OrderStatus.Refund ||
                  this.props.item?.Status === OrderStatus.PromotionExpired ||
                  this.props.item?.Status === OrderStatus.Failed) &&
                this.props.item?.DeliveredQuantity === this.props.item?.Quantity
              }
              label="Refund"
              confirmable
              buttonIcon={Icons.dollarIcon}
            />
            <Button
              disabled={this.props.btnDisable}
              className="confirm-button px-4"
              bindEvent={() => this.setState({ showConfirm: true })}
              visible={this.props.item?.Status === OrderStatus.Confirmed}
              label="Apply Order"
              buttonIcon={Icons.thickIcon}
            />
            <Button
              disabled={this.props.btnDisable}
              className="reject-button px-4"
              bindEvent={() => {
                this.setState({ cancelOrderModalShow: true });
                // this.props.close(this.props.item?.Id);
              }}
              visible={
                this.props.item?.Status === OrderStatus.Processing ||
                this.props.item?.Status === OrderStatus.Failed ||
                this.props.item?.Status === OrderStatus.Paused
              }
              label="Cancel Order"
              buttonIcon={Icons.crossIcon}
            />
            <Button
              disabled={this.props.btnDisable}
              className="reprocess-button px-4"
              bindEvent={() => this.props.reprocess(this.props.item?.Id)}
              visible={this.props.item?.Status === OrderStatus.Failed}
              confirmable
              label=" Reprocess"
              buttonIcon={Icons.reprocessIcon}
            />
            <Button
              className="order-detail-button px-4"
              bindEvent={() => this.setState({ sendInventoryConfirm: true })}
              visible={
                (this.props.item?.Status === OrderStatus.Completed ||
                  this.props.item?.Status === OrderStatus.Closed ||
                  this.props.item?.Status === OrderStatus.PromotionExpired) &&
                this.props.currentUser?.scope[0] !== partner &&
                this.props.item?.Partner?.PartnerId !== SYSTEM_PARTNER_ID
              }
              label="Send To Inventory"
              buttonIcon={Icons.circleIcon}
            />
            <div className="py-2 ps-4 ms-auto">
              <div className="d-flex align-items-center">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input form-check-input-partner"
                    type="checkbox"
                    onChange={(ev) => {
                      services.orders
                        .updateInvoiceCreatingStatus(this.props.item?.Id)
                        .then((res) => {
                          toast.success(
                            <div>
                              The order's DontCreateInvoice status has been
                              updated!
                            </div>
                          );
                          this.refreshItem(this.props.item?.Id);
                        })
                        .catch((err) => {
                          if (err.response?.data?.message)
                            toast.error(
                              <div>{err.response?.data.message}</div>
                            );
                        });
                    }}
                    defaultChecked={this.props.item?.DontCreateInvoice}
                    id="DontCreateInvoice"
                    disabled={
                      this.props.item?.Status !== OrderStatus.Processing &&
                      this.props.item?.Status !== OrderStatus.Submitted &&
                      this.props.item?.Status !== OrderStatus.Paused
                    }
                  />
                </div>
                <div className="d-inline fmw-100">
                  <div className="d-flex">
                    <label
                      className="modal-body-demand-text form-check-label m-0"
                      htmlFor="DontCreateInvoice"
                    >
                      Dont Create Invoice
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-history collapse-right-margin">
            <div className="id-knowledge">
              <div>
                <div className="order-history-title">Order Id</div>
                <div className="order-history-subtitle">
                  {this.props.item?.Id}
                  <Link
                    to={Endpoints.Management.OrderDetail.url.replace(
                      ":" + Endpoints.Management.OrderDetail.url.split(":")[1],
                      this.props.item?.Id
                    )}
                    className="view-detail-link"
                  >
                    <img src={Icons.rightArrowIcon} />
                  </Link>
                </div>
              </div>
              <div>
                <div className="order-history-title">Promotion Id</div>
                <div className="order-history-subtitle">
                  {this.props.item?.Product.VoiduPromotion.PromotionId}
                  <Link
                    to={Endpoints.Management.PromotionDetail.url.replace(
                      ":" +
                        Endpoints.Management.PromotionDetail.url.split(":")[1],
                      this.props.item?.Product.VoiduPromotion.PromotionId
                    )}
                    className="view-detail-link"
                  >
                    <img src={Icons.rightArrowIcon} />
                  </Link>
                </div>
              </div>
              <div>
                <div className="order-history-title">Msku</div>
                <div className="order-history-subtitle">
                  {this.props.item?.Product.ProductSku}
                </div>
              </div>
            </div>
            <div className="order-history-title">Order History</div>
            <div className="order-history-subtitle">
              DP: Demanded Price OP: Offered Price
            </div>
            <div className="order-history-body">
              {this.props.history
                .slice()
                .reverse()
                .map((val, index) => (
                  <div className="history-item-horizontal" key={index}>
                    <div className="history-item-vertical">
                      <i className="far fa-circle" />
                      <div className="history-item">
                        {
                          (moment.locale("en"),
                          moment(convertLocalToUTCDate(val.ModifiedAt)).format(
                            "DD MMMM yyyy, hh:mm:ssA, "
                          ) +
                            val.OrderStatus +
                            " from " +
                            val.ModifiedByName +
                            ", ")
                        }
                        {" " +
                          (val.ModifiedByType === partner ? "DP " : "OP ") +
                          val.OfferPrice +
                          "€ x " +
                          val.OfferQuantity +
                          " =  " +
                          Math.round(val.OfferPrice * val.OfferQuantity * 100) /
                            100 +
                          "€"}
                      </div>
                    </div>
                    {this.props.history.length - 1 > index ? (
                      <i className="fas fa-ellipsis-v" />
                    ) : null}
                  </div>
                ))}
            </div>
          </div>
          <MerModal
            id="order-modal"
            showModal={
              this.state.showConfirm === true ? false : this.state.showModal
            }
            onHide={() => this.handleClose()}
          >
            <ManagementOrderModalLeftSide
              resetInputs={!this.state.showModal}
              btnDisable={this.props.btnDisable}
              rebid={(_demandQuantity, _demandPrice, _orderId) =>
                this.props.rebid(_demandQuantity, _demandPrice, _orderId)
              }
              approve={(
                _orderId,
                _processingStartUTC,
                _processingEndUTC,
                _dontCreateInvoice
              ) =>
                this.props.approve(
                  _orderId,
                  _processingStartUTC,
                  _processingEndUTC,
                  _dontCreateInvoice
                )
              }
              refuse={(_orderId) => this.props.refuse(_orderId)}
              handleClose={() => this.handleClose()}
              confirmModalOpen={() => this.confirmModalOpen()}
              sendInventoryConfirmModalOpen={() =>
                this.sendInventoryConfirmModalOpen()
              }
            />
            <ManagementOrderModalRightSide />
          </MerModal>
          <MerModal
            showModal={this.state.showCreatePackageModal}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => this.handleCreatePackageClose()}
          >
            <ManagementCreatePackageModal
              orderItem={this.props.item}
              handleClose={() => this.handleCreatePackageClose()}
              refreshItem={(id) => this.refreshItem(id)}
            />
          </MerModal>
          <MerModal
            showModal={this.state.showSendPackageModal}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => this.handleSendPackageClose()}
          >
            <ManagementSendPackageModal
              orderItem={this.props.item}
              handleClose={() => this.handleSendPackageClose()}
              refreshItem={(id) => this.refreshItem(id)}
            />
          </MerModal>
          <MerModal
            id="order-confirm"
            showModal={this.state.showConfirm}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => this.confirmModalClose()}
          >
            <ManagementOrderConfirm
              btnDisable={this.props.btnDisable}
              showModal={() => this.confirmModalClose()}
              approveOrder={(
                _processingStartUTC,
                _processingEndUTC,
                _dontCreateInvoice
              ) =>
                this.props.approve(
                  this.props.item?.Id,
                  _processingStartUTC,
                  _processingEndUTC,
                  _dontCreateInvoice
                )
              }
              userRole={this.props.currentUser?.scope}
              status={this.props.item.Status}
              isSalesmanApproveCreatedInvoice={
                this.props.item.DontCreateInvoice
              }
              processingStartDate={this.props.item.ProcessingStartOnUtc}
              processingEndDate={this.props.item.ProcessingEndOnUtc}
              productReleaseDate={this.props.item.Product.ProductReleaseDate}
              voiduPromotion={this.props.item.Product.VoiduPromotion}
            />
          </MerModal>
          <MerModal
            id="send-inventory-confirm"
            showModal={this.state.sendInventoryConfirm}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => this.sendInventoryConfirmModalClose()}
          >
            <ManagementOrderSendToInventoryConfirm
              btnDisable={this.props.btnDisable}
              showModal={() => this.sendInventoryConfirmModalClose()}
              sendToInventory={(params) => {
                this.props.sendToInventory(this.props.item?.Id, params);
              }}
              isBilled={this.props.item.IsBilled}
              packages={this.props.item.Packages}
            />
          </MerModal>

          <MerModal
            id="send-inventory-confirm"
            showModal={this.state.cancelOrderModalShow}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => this.setState({ cancelOrderModalShow: false })}
          >
            <ManagementOrderCancelModal
              btnDisable={this.props.btnDisable}
              showModal={() => this.setState({ cancelOrderModalShow: false })}
              cancel={(sendBackOrderedItemsToInventory) =>
                this.props.close(
                  this.props.item?.Id,
                  sendBackOrderedItemsToInventory
                )
              }
              Id={this.props.item?.Id}
              isBilled={this.props.item.IsBilled}
              packages={this.props.item.Packages}
            />
          </MerModal>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementOrderCollapseBody);
