import React from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { Route, Switch } from "react-router-dom";
import { push } from "react-router-redux";

import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import LoginPage from "./pages/LoginPage";
import PromotionsManagement from "./pages/management/promotions/Promotions";
import AllPromotions from "./pages/partner/promotions/AllPromotions";
import Basket from "./pages/partner/basket/Basket";
import Users from "./pages/management/users/Users";
import EditUser from "./pages/management/users/EditUser";
import MyOrders from "./pages/partner/my-orders/MyOrders";
import MyUnInvoicedOrder from "./pages/partner/UnInvoicedOrders";
import DirectSales from "./pages/partner/promotions/DirectSales";
import MyCreditLimit from "./pages/partner/MyCreditLimit";
import CreditLimits from "./pages/management/CreditLimits";
import Orders from "./pages/management/orders/Orders";
import OrderDetail from "./pages/management/orders/OrderDetail";
import ManagementIndex from "./pages/management/Index";
import PageNotFound from "./components/PageNotFound";
import MyInvoices from "./pages/partner/MyInvoices";
import Invoices from "./pages/management/Invoices";
import UnInvoicedOrders from "./pages/management/UnInvoicedOrders";
import ManagementRegionPage from "./pages/management/regions";

import NewPromotions from "./pages/management/promotions/new-promotion/NewPromotions";
import ProviderProducts from "./pages/management/provider-products/ProviderProducts";
import Vendors from "./pages/management/vendors/vendors";
// import Allocations from "./pages/management/allocations/Allocations";
import Inventories from "./pages/management/inventories/Inventories";
import TopSellers from "./pages/management/reports/TopSellers";
import ProductReport from "./pages/management/reports/ProductReport";
import { REDIRECT, LOGIN, PERMISSION_LOADED } from "./constants/actionTypes";

import PromotionDetail from "./pages/management/orders/PromotionDetail";
import PermissionGroup from "./pages/management/PermissionGroup";
import Companies from "./pages/management/companies/Companies";
import BulkOrder from "./pages/management/orders/BulkOrder";
import MyAccount from "./pages/partner/myaccount/MyAccount";
import BulkPromotion from "./pages/partner/promotions/bulk-promotion/BulkPromotion";
import UpcomingProviderPromotions from "./pages/management/upcoming-promotions/UpcomingProviderPromotions";
import UpcomingProducts from "./pages/management/upcoming-promotions/UpcomingProducts";
import UpcomingPromotionsPartner from "./pages/partner/UpcomingPromotionsPartner";
import WindowsResizing from "./WindowsResizing";
import InventoryReports from "./pages/management/reports/InventoryReposts";
import InventoryReportsFinance from "./pages/report/InventoryReposts";
import CreditNotes from "./pages/management/CreditNotes";
import ManuelStock from "./pages/management/ManualInventory";
import CompanyAccount from "./pages/management/company-account-module/CompanyAccount";
import Settings from "./pages/management/Settings";

import { PrivateRoute } from "./pages/PrivateRoute";

import { Endpoints } from "./constants/endpoints";
import { store } from "./store";
import services from "./api/index";
import { finance, partner } from "./constants/defaults";
import MonthlyRevenueReport from "./pages/management/reports/MonthlyRevenueReport";
import OrderProcessReport from "./pages/management/reports/OrderProcessReport";

const mapStateToProps = (state) => {
  return {
    redirectTo: state.common.redirectTo,
    jwt: state.auth.jwt,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRedirect: () => dispatch({ type: REDIRECT }),
  onLogin: (payload) =>
    dispatch({
      type: LOGIN,
      payload,
    }),
  getPermission: (payload) => dispatch({ type: PERMISSION_LOADED, payload }),
});

class App extends React.Component {
  constructor(props) {
    super(props);
    if (!window.location.pathname.includes("/login")) this.getPermission();

    const jwt = JSON.parse(localStorage.getItem("jwt"));
    this.props.onLogin(jwt);
  }

  // eslint-disable-next-line react/no-deprecated, react/sort-comp
  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      let jwt = JSON.parse(localStorage.getItem("jwt"));
      let decoded_jwt = jwt ? jwt_decode(jwt?.AccessToken) : null;
      let redirectTo = nextProps.redirectTo;

      if (decoded_jwt !== null && !redirectTo.includes("login")) {
        if (
          decoded_jwt?.scope.includes(partner) &&
          redirectTo.includes("management")
        ) {
          redirectTo = Endpoints.Partner.Home.url;
        } else if (
          decoded_jwt.scope.includes(finance) &&
          !redirectTo.includes(Endpoints.Partner.MyAccount.url)
        ) {
          redirectTo = Endpoints.Management.InventoryReports.url;
        } else if (
          !redirectTo.includes("management") &&
          decoded_jwt?.scope.includes("SuperAdmin") &&
          !redirectTo.includes(Endpoints.Partner.MyAccount.url)
        ) {
          redirectTo = Endpoints.Management.Home.url;
        }
      }
      if (!redirectTo.includes("/login")) this.getPermission();

      store.dispatch(push(redirectTo));
      this.props.onRedirect();
    }
  }

  getPermission() {
    services.user.getPermission().then((res) => {
      let permissions = res.reduce((result, word) => {
        const letter = word.split(".")[0];
        result[letter] = result[letter] || [];
        result[letter].push(word.split(".")[1]);

        return result;
      }, {});

      this.props.getPermission(permissions);
    });
  }

  render() {
    const jwt = this.props.jwt ? this.props.jwt : null;
    return (
      <div>
        <WindowsResizing></WindowsResizing>
        {this.props.jwt &&
        localStorage.getItem("jwt") &&
        !window.location.pathname.includes("login") ? (
          <Sidebar />
        ) : (
          <></>
        )}
        {this.props.jwt && localStorage.getItem("jwt") ? (
          <Navbar currentUser={jwt_decode(jwt.AccessToken)} />
        ) : (
          <></>
        )}
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <PrivateRoute
            exact
            path={Endpoints.Management.Home.url}
            component={ManagementIndex}
          />
          <PrivateRoute
            path={Endpoints.Management.Promotions.url}
            component={PromotionsManagement}
          />
          <PrivateRoute
            path={Endpoints.Management.NewPromotions.url}
            component={NewPromotions}
          />
          <PrivateRoute
            path={Endpoints.Management.EditPromotion.url}
            component={NewPromotions}
          />
          <PrivateRoute
            path={Endpoints.Management.Orders.url}
            component={Orders}
          />
          <PrivateRoute
            path={Endpoints.Management.BulkOrder.url}
            component={BulkOrder}
          />
          <PrivateRoute
            path={Endpoints.Management.OrderDetail.url}
            component={OrderDetail}
          />
          <PrivateRoute
            path={Endpoints.Management.PromotionDetail.url}
            component={PromotionDetail}
          />
          <PrivateRoute
            path={Endpoints.Management.Invoices.url}
            component={Invoices}
          />

          <PrivateRoute
            path={Endpoints.Management.UnInvoicedOrders.url}
            component={UnInvoicedOrders}
          />
          <PrivateRoute
            path={Endpoints.Management.CreditNotes.url}
            component={CreditNotes}
          />

          <PrivateRoute
            path={Endpoints.Management.Settings.url}
            component={Settings}
          />

          <PrivateRoute
            path={Endpoints.Management.Inventories.url}
            component={Inventories}
          />
          <PrivateRoute
            path={Endpoints.Management.UpcomingProviderPromotions.url}
            component={UpcomingProviderPromotions}
          />
          <PrivateRoute
            path={Endpoints.Management.UpcomingProducts.url}
            component={UpcomingProducts}
          />
          <PrivateRoute
            path={Endpoints.Management.ProviderProducts.url}
            component={ProviderProducts}
          />
          <PrivateRoute
            path={Endpoints.Management.CompanyAccount.url}
            component={CompanyAccount}
          />

          <PrivateRoute
            path={Endpoints.Management.Companies.url}
            component={Companies}
          />
          <PrivateRoute
            path={Endpoints.Management.Users.url}
            component={Users}
          />
          <PrivateRoute
            path={Endpoints.Management.EditUser.url}
            component={EditUser}
          />
          <PrivateRoute
            path={Endpoints.Management.CreditLimits.url}
            component={CreditLimits}
          />
          <PrivateRoute
            path={Endpoints.Management.TopSellers.url}
            component={TopSellers}
          />
          <PrivateRoute
            path={Endpoints.Management.MonthlyRevenue.url}
            component={MonthlyRevenueReport}
          />
          <PrivateRoute
            path={Endpoints.Management.OrderProcessByStatus.url}
            component={OrderProcessReport}
          />
          <PrivateRoute
            path={Endpoints.Management.ProductReport.url}
            component={ProductReport}
          />
          <PrivateRoute
            path={Endpoints.Management.PermissionGroup.url}
            component={PermissionGroup}
          />

          <PrivateRoute
            path={Endpoints.Partner.Basket.url}
            component={Basket}
          />
          <PrivateRoute
            path={Endpoints.Partner.AllPromotions.url}
            component={AllPromotions}
          />
          <PrivateRoute
            path={Endpoints.Partner.BulkPromotion.url}
            component={BulkPromotion}
          />
          <PrivateRoute
            path={Endpoints.Partner.MyOrders.url}
            component={MyOrders}
          />
          <PrivateRoute
            path={Endpoints.Partner.DirectSales.url}
            component={DirectSales}
          />
          <PrivateRoute
            path={Endpoints.Partner.MyInvoices.url}
            component={MyInvoices}
          />
          <PrivateRoute
            path={Endpoints.Partner.MyUnInvoiceOrders.url}
            component={MyUnInvoicedOrder}
          />

          <PrivateRoute
            path={Endpoints.Partner.UpcomingPromotionsPartner.url}
            component={UpcomingPromotionsPartner}
          />
          <PrivateRoute
            path={Endpoints.Partner.MyCreditLimit.url}
            component={MyCreditLimit}
          />
          <PrivateRoute
            path={Endpoints.Partner.MyAccount.url}
            component={MyAccount}
          />
          <PrivateRoute
            path={Endpoints.Management.InventoryReportsManagement.url}
            component={InventoryReports}
          />
          <PrivateRoute
            path={Endpoints.Management.InventoryReports.url}
            component={InventoryReportsFinance}
          />

          <PrivateRoute
            path={Endpoints.Management.Vendors.url}
            component={Vendors}
          />
          <PrivateRoute
            path={Endpoints.Management.ManuelStock.url}
            component={ManuelStock}
          />

          <PrivateRoute
            path={Endpoints.Management.MerkurRegions.url}
            component={ManagementRegionPage}
          />

          <Route path="/login/:returnurl" component={LoginPage} />
          <Route path="/login" component={LoginPage} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
