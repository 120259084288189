import React, { useState } from "react";
import { Icons } from "../../../../../constants/icons";
import { Button } from "react-bootstrap";
import api from "../../../../../api";
import { toast } from "react-toastify";

const ManagementOrderBlendingUpdateModel = ({
  order = {},
  onClose = () => {},
}) => {
  const [data, setData] = useState(() =>
    order.Blending?.reduce(
      (prev, curr) => ({ ...prev, [curr.Code]: curr.Quantity }),
      {}
    )
  );

  const currQuantity = Object.values(data || {}).reduce(
    (prev, curr) => prev + curr,
    0
  );
  const isQuantityExcited = currQuantity !== order.Quantity;

  const onChange = (e) => {
    const { target: { name, value } = {} } = e;
    setData((prev) => ({ ...prev, [name]: parseInt(value) }));
  };

  const onSubmit = () => {
    (async () => {
      try {
        const countries = Object.keys(data || {}).reduce(
          (prev, curr) => [
            ...prev,
            {
              Code: curr,
              Quantity: data[curr],
            },
          ],
          []
        );

        await api.orders.updateManagementOrderBlending(order.Id, countries);

        toast.success(
          <div>The order blending has been updated successfully!</div>
        );

        setTimeout(() => {
          window.location.reload();
        }, 500);

        return;
      } catch (err) {
        if (err.errors) {
          toast.error(
            <div>
              {Object.keys(err.errors).map((e) => (
                <span>
                  {e}: {JSON.stringify(err.errors[e])}
                </span>
              ))}
            </div>
          );

          return;
        }

        toast.error(
          <div>{err?.response?.data.message || "Unknown error"}</div>
        );
      }
    })();
  };

  return (
    <div className="container create-package-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="create-package-header">
          <span>Update Blending</span>
        </div>
        <div>
          <form>
            <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer">
              <thead>
                <tr>
                  <th>Country Code</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data || {}).map((e) => (
                  <tr key={e} className="my-3">
                    <td className="mx-3">
                      <span>{e}</span>
                    </td>
                    <td>
                      <input
                        name={e}
                        value={data[e] || 0}
                        type="number"
                        step={1}
                        min={0}
                        max={order.Quantity || undefined}
                        onChange={onChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr
                  style={{
                    backgroundColor: isQuantityExcited
                      ? "#ff0000cc"
                      : "transparent",
                  }}
                >
                  <td>Total Quantity</td>
                  <td>
                    {currQuantity}
                    <span className="mx-1">/</span>
                    {order.Quantity}
                  </td>
                </tr>
              </tfoot>
            </table>
          </form>
        </div>
        <div
          className="mt-4"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <button
            style={{
              border: "none",
              borderRadius: 24,
              backgroundColor: "rgb(239, 87, 111)",
              padding: "8px 12px",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            style={{
              border: "none",
              borderRadius: 24,
              backgroundColor: "rgb(179, 213, 21)",
              padding: "8px 12px",
            }}
            onClick={() => onSubmit()}
            disabled={isQuantityExcited}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManagementOrderBlendingUpdateModel;
