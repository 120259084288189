// eslint-disable-next-line import/no-cycle
import { OrderStatus, OrderPaidStatus } from "./defaults";
import { Icons } from "./icons";

export const ColumnsType = {
  Text: "1",
  Number: "2",
  RegionTable: "3",
  OrderFlag: "4",
  Button: "5",
  OfferValue: {
    Partner: {
      DemandedOffer: {
        Price: "6",
        Quantity: "7",
      },
      LatestOffer: {
        Price: "8",
        Quantity: "9",
      },
    },
    Management: {
      DemandedOffer: {
        Price: "10",
        Quantity: "11",
      },
      LatestOffer: {
        Price: "12",
        Quantity: "13",
      },
    },
  },
  Date: "14",
  OperatingSystems: "15",
  Platforms: "16",
  Sum: "17",
  PaymentStatus: "18",
  InvoiceStatus: "21",
  Bool: "22",
  EditButton: "23",
  Checkbox: "24",
};

export const ButtonType = {
  Info: "1",
  Warning: "2",
  Danger: "3",
  Secondary: "4",
  Primary: "5",
  Success: "6",
};

export const FontWeight = {
  bold: "1",
  bolder: "2",
  normal: "3",
  light: "4",
  lighter: "5",
  italic: "6",
};

export const OrderStatusDesc = (status) => {
  switch (status) {
    case OrderStatus.Processing:
      return {
        srcIcon: Icons.processingIcon,
        iconColor: "white",
        statusDesc: "Processing",
        subStatus: OrderStatus.Processing,
      };
    case OrderStatus.Confirmed:
      return {
        srcIcon: Icons.thickIcon,
        iconColor: "yellowish-green",
        statusDesc: "Confirmed",
        subStatus: "Order's been confirmed.",
      };
    case OrderStatus.Completed:
      return {
        srcIcon: Icons.thickIcon,
        iconColor: "yellowish-green",
        statusDesc: "Completed",
        subStatus: OrderStatus.Completed,
      };
    case OrderStatus.Refunded:
      return {
        srcIcon: Icons.dollarIcon,
        iconColor: "yellowish-green",
        statusDesc: "Refunded",
        subStatus: "Order is refunded!",
      };
    case OrderStatus.Rebidding:
      return {
        srcIcon: Icons.attentionIcon,
        iconColor: "mango",
        statusDesc: "Rebidding",
        subStatus: "You have a new special offer just for you!",
      };
    case OrderStatus.Revoked:
      return {
        srcIcon: Icons.rightArrow45Icon,
        iconColor: "mango",
        statusDesc: "Revoked",
        subStatus: "Order is revoked!",
      };
    case OrderStatus.Withdraw:
      return {
        srcIcon: Icons.rightArrow135Icon,
        iconColor: "mango",
        statusDesc: "Withdrawn",
        subStatus: "Order is withdrawn!",
      };
    case OrderStatus.Rejected:
      return {
        srcIcon: Icons.cancelIcon,
        iconColor: "carnation",
        statusDesc: "Rejected",
        subStatus: "Contact to sales if need asistance with this order.",
      };
    case OrderStatus.Refused:
      return {
        srcIcon: Icons.cancelIcon,
        iconColor: "carnation",
        statusDesc: "Refused",
        subStatus: "Order is refused!",
      };
    case OrderStatus.Failed:
      return {
        srcIcon: Icons.crossIcon,
        iconColor: "carnation",
        statusDesc: "Failed",
        subStatus: "Order cannot be processed, please contact our sales team!",
      };
    case OrderStatus.Submitted:
      return {
        srcIcon: Icons.rightArrowIcon,
        iconColor: "dark-slate-blue",
        statusDesc: "Submitted",
        subStatus: "Your order is under review!",
      };
    case OrderStatus.Closed:
      return {
        srcIcon: Icons.minusIcon,
        iconColor: "dark-slate-blue",
        statusDesc: "Closed",
        subStatus: OrderStatus.Closed,
      };
    case OrderStatus.Cancelled:
      return {
        srcIcon: Icons.crossIcon,
        iconColor: "cloudy-blue",
        statusDesc: "Cancelled",
        subStatus:
          "Offer cancelled, please contact our sales team if you think otherwise!",
      };
    case OrderStatus.Refund:
      return {
        srcIcon: Icons.dollarIcon,
        iconColor: "yellowish-green",
        statusDesc: "Refund",
        subStatus: "Order is refunded!",
      };
    case OrderStatus.Transferred:
      return {
        srcIcon: Icons.transferredIcon,
        iconColor: "cloudy-blue",
        statusDesc: "Transferred",
        subStatus: "Order is transferred!",
      };
    case OrderStatus.Draft:
      return {
        srcIcon: Icons.crossIcon,
        iconColor: "",
        statusDesc: "Draft",
        subStatus: "",
      };
    case OrderStatus.SalesmanApproved:
      return {
        srcIcon: Icons.thickIcon,
        iconColor: "yellowish-green",
        statusDesc: "Salesman Approved",
        subStatus: "Order's been confirmed.",
      };
    case OrderStatus.Level1Approved:
      return {
        srcIcon: Icons.rightArrowIcon,
        iconColor: "dark-slate-blue",
        statusDesc: "Level 1 Approved",
        subStatus: "Order's been confirmed.",
      };
    case OrderStatus.AdminRefused:
      return {
        srcIcon: Icons.cancelIcon,
        iconColor: "carnation",
        statusDesc: "Admin Refused",
        subStatus: "Order is refused!",
      };
    case OrderStatus.Paused:
      return {
        srcIcon: Icons.cancelIcon,
        iconColor: "mango",
        statusDesc: "Paused",
        subStatus: OrderStatus.Paused,
      };
    case OrderStatus.OutOfStock:
      return {
        srcIcon: Icons.cancelIcon,
        iconColor: "gray",
        statusDesc: "Out Of Stock",
        subStatus: OrderStatus.OutOfStock,
      };

    case OrderStatus.PromotionExpired:
      return {
        srcIcon: Icons.cancelIcon,
        iconColor: "gray",
        statusDesc: "Promotion Exriped",
        subStatus: OrderStatus.PromotionExpired,
      };
    default:
      return null;
  }
};

export const OrderPaidStatusDesc = (status) => {
  switch (status) {
    case OrderPaidStatus.Paid:
      return {
        srcIcon: Icons.thickIcon,
        iconColor: "yellowish-green",
        statusDesc: "Paid",
        subStatus: OrderStatus.Paid,
      };
    case OrderPaidStatus.UnPaid:
      return {
        srcIcon: Icons.iconCreditLimit,
        iconColor: "cloudy-blue",
        statusDesc: "UnPaid",
        subStatus: OrderPaidStatus.UnPaid,
      };
    case OrderPaidStatus.PartyPaid:
      return {
        srcIcon: Icons.iconCreditLimit,
        iconColor: "mango",
        statusDesc: "PartialPaid",
        subStatus: OrderPaidStatus.PartyPaid,
      };
    default:
      return null;
  }
};
