import React from "react";
import { Icons } from "../../../../../constants/icons";
import Button from "../../../../../components/common/Button";
import { toast } from "react-toastify";
import services from "../../../../../api/index";

const ManagementOrderHardStopModal = (props) => {
  const hardStopCallback = () => {
    services.orders
      .hardStopOrder(props.order.order[0].Id)
      .then((res) => {
        toast.success(
          <div>The order has been forced to stop successfully!</div>
        );
        props.closeModal();

        setTimeout(() => {
          window.location.reload();
        }, 500);
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  return (
    <div className="order-confirm-modal send-inventory-confirm">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon}></img>
      </div>
      <div className="order-confirm-title">
        <label>Order will be stopped, are you sure to proceed?</label>
      </div>

      <div className="px-4 p-2 rounded-1 m-auto"></div>
      <div>
        <Button
          disabled={props.btnDisable}
          className="order-confirm-yes-button"
          bindEvent={() => hardStopCallback()}
          label="Proceed"
          buttonIcon={Icons.thickIcon}
        />
      </div>
      <div>
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.closeModal()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default ManagementOrderHardStopModal;
