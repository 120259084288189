import React, { useCallback, useState } from "react";

import Button from "../../../../components/common/Button";
import MerModal from "../../../../components/common/MerModal";
import { Icons } from "../../../../constants/icons";
import api from "../../../../api";
import { toast } from "react-toastify";

const RequestProductModal = () => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        if (loading) return;
        setLoading(true);

        const sku = e.target.sku.value;

        await api.product.syncProductPriceBySky(sku);
        toast.success(
          <div>Product and prices should appear in 1-2 minute!</div>
        );
        setOpen(false);
      } catch (err) {
        toast.error(
          <div>
            {err.response?.data.message || err.message || "Unknown error"}
          </div>
        );
      } finally {
        setLoading(false);
      }
    },
    [loading]
  );

  return (
    <div>
      <Button
        className="create_promotion_button px-2"
        label="Sync Product by SKU"
        bindEvent={() => setOpen(true)}
        buttonIcon={Icons.nullIcon}
        iconWidth={16}
        iconHeight={16}
      />
      <MerModal
        id="request-product-modal"
        ariaLabelledby="contained-modal-title-vcenter"
        showModal={isOpen}
        onHide={() => setOpen(false)}
      >
        <div className="table-responsive-xl px-5 py-4 h-100">
          <form onSubmit={onSubmit}>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex align-items-start gap-4">
                <div className="flex-grow-1">
                  <div className="d-flex flex-column user-input gap-2">
                    <label htmlFor="invoicePeriod" className="form-label">
                      Provider
                    </label>
                    <select disabled>
                      <option value="Genba">Genba</option>
                    </select>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex flex-column user-input gap-2">
                    <label htmlFor="invoicePeriod" className="form-label">
                      SKU
                    </label>
                    <input id="sku" type="string" name="sku" />
                  </div>
                </div>
              </div>
              <button
                disabled={loading}
                className="btn btn-success"
                type="submit"
              >
                Sync
              </button>
            </div>
          </form>
        </div>
      </MerModal>
    </div>
  );
};

export default RequestProductModal;
