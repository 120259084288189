import React from "react";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import BlankPage from "../../../components/BlankPage";
import Content from "../../../components/containers/Content";
import { connect } from "react-redux";
import {
  UPCOMING_PROMOTION,
  COMPANIES_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import Pager from "../../../components/Pager";
import base64 from "base-64";
import Filter from "../../../components/Filter";
import { defaultDateFormat } from "../../../constants/defaults";
import moment from "moment";
import Button from "../../../components/common/Button";
import services from "../../../api/index";
import { Endpoints } from "../../../constants/endpoints";
import { Icons } from "../../../constants/icons";
import { Tab, Tabs } from "react-bootstrap";
import MerModal from "../../../components/common/MerModal";
import GoogleSheetConfirm from "../provider-products/components/GoogleSheetConfirm";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    companies: state.companies,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  upcomingPromotionsOnLoad: (payload) => {
    dispatch({ type: UPCOMING_PROMOTION, payload });
  },
  companiesOnLoad: (payload) => {
    dispatch({ type: COMPANIES_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

class UpcomingProviderPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.match.params.filter
        ? JSON.parse(base64.decode(this.props.match.params.filter))
        : null,
      pageindex: 0,
      tableIsLoading: false,
      selectedTab: "upcoming-promotions",
      showConfirmModal: false,
      showConfirUpcomingmModal: false,
      selectedPromotionId: "",
    };
  }
  componentDidMount() {
    document.title = "Provider Promotions";
    this.getUpcomingPromotions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  getUpcomingPromotions = (params) => {
    this.setState({ tableIsLoading: true });
    const { pageindex = this.state.pageindex, pagesize = this.props.pageSize } =
      params || {};
    this.props.upcomingPromotionsOnLoad(
      Promise.all([
        services.campaigns.upcomingProviderPromotions(pageindex, pagesize),
      ])
        .then((res) => {
          this.setState({ tableIsLoading: false });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  getActivePromotions = (params) => {
    this.setState({ tableIsLoading: true });
    const { pageindex = this.state.pageindex, pagesize = this.props.pageSize } =
      params || {};
    this.props.upcomingPromotionsOnLoad(
      Promise.all([
        services.campaigns.activeProviderPromotions(pageindex, pagesize),
      ])
        .then((res) => {
          this.setState({ tableIsLoading: false });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  updateGoogleSheet = () => {
    return Promise.all([
      services.googleSheet
        .updateGoogleSheet()
        .then((res) => {
          toast.success(<div>Google sheet have been updated!</div>);
          this.setState({ showConfirmModal: false });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        }),
    ]);
  };

  updateUpcomingGoogleSheet = () => {
    if (!this.state.selectedPromotionId) return undefined;

    return Promise.all([
      services.googleSheet
        .updateUpcomingGoogleSheet(this.state.selectedPromotionId)
        .then((res) => {
          toast.success(<div>Google sheet have been updated!</div>);
          this.setState({
            showConfirUpcomingmModal: false,
            selectedPromotionId: "",
          });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        }),
    ]);
  };

  updateProductPriceUpdateWithActiveDiscountRate = (publisherId) => {
    services.campaigns
      .updateProductPriceUpdateWithActiveDiscountRate(publisherId)
      .then(() => {
        toast.success(<div>The Price Updated!</div>);
        return true;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  genbaUpcomingPromotion = (promotionId) => {
    services.googleSheet
      .genbaUpcomingPromotions(promotionId)
      .then(() => {
        toast.success(<div>Added The Google Sheet!</div>);
        return true;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  genbaActivePromotion = (promotionId) => {
    services.googleSheet
      .genbaActivePromotions(promotionId)
      .then(() => {
        toast.success(<div>Added The Google Sheet!</div>);
        return true;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  onFilter = (params) => {
    let { filter, pageindex = 0, activeTab = "" } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;

    if (activeTab == "active-promotions") {
      this.getActivePromotions({
        pageindex: pageindex,
      });
    } else {
      this.getUpcomingPromotions({
        pageindex: pageindex,
      });
    }
  };

  onChangePageIndex = (pageindex) => {
    this.setState({ tableIsLoading: true, pageindex: pageindex });

    if (this.state.selectedTab == "active-promotions") {
      this.getActivePromotions({
        pageindex: pageindex,
      });
    } else {
      this.getUpcomingPromotions({
        pageindex: pageindex,
      });
    }
  };

  setTabKey(key) {
    console.log(key);
    this.setState({ selectedTab: key });

    if (key == "active-promotions") {
      this.onFilter({ activeTab: key });
    } else {
      this.onFilter({});
    }
  }

  render() {
    const { upcomingPromotions } = this.props.promotions;
    const { companyList } = this.props.companies;

    return (
      <Content pageTitle="Provider Promotions">
        {upcomingPromotions == undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              hideSearch
              onFilter={(ev) => this.onFilter(ev)}
            ></Filter>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <div>
                  <Tabs
                    id="management-orders-tab-menu"
                    activeKey={this.state.selectedTab}
                    onSelect={(k) => {
                      this.setTabKey(k);
                    }}
                  >
                    <Tab
                      eventKey="upcoming-promotions"
                      title="Upcoming Promotions"
                    ></Tab>
                    <Tab
                      eventKey="active-promotions"
                      title="Active Promotions"
                    ></Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            {this.state.tableIsLoading ? (
              <Loader />
            ) : upcomingPromotions[0]?.Items.length === 0 ? (
              <BlankPage description="There is no upcoming promotion." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {upcomingPromotions[0]?.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">
                          Publisher Name
                        </th>
                        <th className="bg-transparent border-0 ">Start Date</th>
                        <th className="bg-transparent border-0 ">End Date</th>

                        <th className="bg-transparent border-0 ">
                          Product Count
                        </th>
                        <th className="bg-transparent border-0 ">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upcomingPromotions[0]?.Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td>
                              <p>{item.PublisherName}</p>
                            </td>
                            <td>
                              <p>
                                {moment(item.ValidFrom).format(
                                  defaultDateFormat + "\tH:mm:ss"
                                )}
                              </p>
                            </td>
                            <td>
                              <p>
                                {moment(item.ValidTo).format(
                                  defaultDateFormat + "\tH:mm:ss"
                                )}
                              </p>
                            </td>
                            <td>
                              <p>{item.ProductCount}</p>
                            </td>
                            <td className="ps-0">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                {this.state.selectedTab ==
                                "active-promotions" ? (
                                  <Button
                                    className="order-detail-view-detail-button"
                                    bindEvent={() =>
                                      this.genbaActivePromotion(
                                        item.PromotionId
                                      )
                                    }
                                    registerTip="2"
                                    tooltipDesc="Genba Active Promotion"
                                    label=""
                                  >
                                    <img src={Icons.addFromGoogleSheetIcon} />
                                  </Button>
                                ) : (
                                  <Button
                                    className="order-detail-view-detail-button"
                                    bindEvent={() =>
                                      this.genbaUpcomingPromotion(
                                        item.PromotionId
                                      )
                                    }
                                    registerTip="2"
                                    tooltipDesc="Genba Upcoming Promotion"
                                    label=""
                                  >
                                    <img src={Icons.addFromGoogleSheetIcon} />
                                  </Button>
                                )}
                                {this.state.selectedTab ==
                                "active-promotions" ? (
                                  <Button
                                    className="order-detail-view-detail-button"
                                    bindEvent={() =>
                                      this.setState({ showConfirmModal: true })
                                    }
                                    label=""
                                    registerTip="3"
                                    tooltipDesc="Update Price Master"
                                  >
                                    <img src={Icons.updateGoogleSheetIcon} />
                                  </Button>
                                ) : (
                                  <Button
                                    className="order-detail-view-detail-button"
                                    bindEvent={() =>
                                      this.setState({
                                        showConfirUpcomingmModal: true,
                                        selectedPromotionId: item.PromotionId,
                                      })
                                    }
                                    label=""
                                    registerTip="3"
                                    tooltipDesc="Update Price Master"
                                  >
                                    <img src={Icons.updateGoogleSheetIcon} />
                                  </Button>
                                )}

                                <Button
                                  className="order-detail-view-detail-button"
                                  bindEvent={() => {
                                    this.props.onRedirect(
                                      Endpoints.Management.UpcomingProducts.url
                                        .replace(
                                          ":filter?",
                                          base64.encode(
                                            JSON.stringify({
                                              Promotions: [item.PromotionId],
                                              PublishersName: [
                                                item.PublisherName,
                                              ],
                                              OnlyActivePromotion: "1",
                                              PromotionType:
                                                this.state.selectedTab,
                                            })
                                          )
                                        )
                                        .replace(":sort?", "0")
                                        .replace(":page?", 0)
                                    );
                                  }}
                                  label=""
                                  registerTip="1"
                                  tooltipDesc="View Detail"
                                >
                                  <img src={Icons.viewDetailIcon} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={this.state.pageindex}
                  totalPages={upcomingPromotions[0]?.TotalPages}
                  totalCount={upcomingPromotions[0]?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
              </div>
            )}

            <MerModal
              id="order-confirm"
              showModal={this.state.showConfirmModal}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => this.setState({ showConfirmModal: false })}
            >
              <GoogleSheetConfirm
                handleClose={() => this.setState({ showConfirmModal: false })}
                updateGoogleSheet={() => this.updateGoogleSheet()}
              ></GoogleSheetConfirm>
            </MerModal>

            <MerModal
              id="order-confirm"
              showModal={this.state.showConfirUpcomingmModal}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => this.setState({ showConfirUpcomingmModal: false })}
            >
              <GoogleSheetConfirm
                handleClose={() =>
                  this.setState({ showConfirUpcomingmModal: false })
                }
                updateGoogleSheet={() => this.updateUpcomingGoogleSheet()}
              ></GoogleSheetConfirm>
            </MerModal>
          </div>
        )}
      </Content>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingProviderPromotions);
